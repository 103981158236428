.fab {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

:global #root {
  display: grid;

  grid-template-columns: 192px 1fr;
  grid-template-rows: 64px 1fr;
  grid-column-gap: 10px;
  grid-template-areas:
    "nav    nav"
    "sidebar  body";

  background-color: #fff;
  min-height: 100vh;
}

.header {
  grid-area: nav;
}

.sidebar {
  grid-area: sidebar;
}

.body {
  grid-area: body;
}
