.header {
  /* background-color: #fafbfc; */
  background-color: #263238;
  padding: 10px 20px;
  color: #fff;

  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);

  display: flex;
  justify-content: space-between;
}
