.sidebar {
  background-color: #263238;
  /* position: fixed;

	height: 100%;
	width: 230px; */

  color: #fff;
  box-sizing: border-box;
  padding: 5px;

  z-index: 20;
}

.logo {
  margin-bottom: 30px;
  width: 90%;
}

.sidebarList {
  list-style: none;
  padding-left: 0px;
}

.sidebarList li {
  width: 100%;
  /* height: 40px; */
  cursor: pointer;

  line-height: 36px;
  font-size: 16px;

  text-decoration: none;

  text-indent: 25px;
}

.sidebarList li:hover {
  border-left: 3px solid #e99d1a;
  text-indent: 22px;
}

.sidebar a {
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  text-indent: 25px;
  font-weight: 300;
}
