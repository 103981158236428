.card {
  min-height: 150px;
  width: 400px;
  padding: 5px;
  line-height: 25px;
  border-radius: 5px;
  margin: 5px;
  margin-top: 25px;
}

.header {
  display: grid;
  grid-template-columns: 50px 1fr auto auto;
  column-gap: 10px;
  height: 50px;
  margin-top: -20px;
  padding: 5px;
  padding-bottom: 0px;
  color: #fff !important;
  font-size: 14px;
}

.header.running {
  background-color: #43a047 !important;
}

.header.stopped {
  background-color: #fb8c00 !important;
}

.body {
  padding: 5px;
  line-height: 30px;
  font-weight: 500;
}

.footer {
  font-size: 11px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-basis: 110;
}

.footer > .info {
  line-height: 1.5;
}

.applicationList {
  margin-bottom: 10px;

  display: flex;
  justify-content: space-around;
}

.link {
  font-size: 14px;
}

.codeList {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  column-gap: 5px;
  align-items: end;
}

.codeInput {
  background-color: #fafbfc;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  padding: 3px 10px;
  font-size: 12px;
  line-height: 20px;

  color: #24292e;
  vertical-align: middle;

  border: 1px solid #e1e4e8;
  border-radius: 4px;
  outline: none;
  box-shadow: inset 0 1px 0 rgba(225, 228, 232, 0.2);

  width: auto;
  height: 15px;
}

.icon {
  cursor: pointer;
}
